
function RenderChart(element) {

    var method = function () {
        var jsonParams = $(element).attr("data-chartoptions");
        if (jsonParams != null) {
            jsonParams = $.parseJSON(jsonParams);
        }

        var jsonData = $.parseJSON($(element).attr("data-chartdata"));
        var data = new google.visualization.DataTable(jsonData);
        var htmlElement = $(element)[0];

        var chart = null;
        switch ($(element).attr("data-chart")) {
            case "Combo":
                chart = new google.visualization.ComboChart(htmlElement);
                break;
            case "Table":
                chart = new google.visualization.Table(htmlElement);
                break;
        }

        chart.draw(data, jsonParams);
    };

    if (typeof google.visualization === 'undefined') {
        google.setOnLoadCallback(method);
    }
    else {
        method();
    }
}

var GoogleChart = function (chartElement) {
    return {
        chart: new google.visualization.ComboChart(chartElement),
        series: [],
        data: new google.visualization.DataTable(),
        params: null,
        columns: {},

        draw: function () {
            var _params = this.params;
            _params.series = this.series;
            _params.animation = {
                duration: 500,
                easing: 'out',
            };
            this.chart.draw(this.data, _params);
        },

        predraw: function () {
            var _params = this.params;
            _params.series = this.series;
            _params.animation = {
                duration: preDrawDuration,
                easing: 'out',
            };
            this.chart.draw(this.data, _params);
        },

        removeDataById: function (id) {
            // clean series
            var newSeries = []
            this.series.forEach(function (single) {
                if (single.id != id) {
                    newSeries.push(single);
                }
            });
            this.series = newSeries;

            // clean data columns
            for (var i = 0; i < this.data.getNumberOfColumns() ; i++) {
                if (id == this.data.getColumnId(i)) {
                    this.data.removeColumn(i);
                    i--;
                }
            }

        }
    };
}

var toDataTableFromJson = function (json) {
    var data = new google.visualization.DataTable();
    
    for (colIndex in json.Columns) {
        var col = json.Columns[colIndex];

        data.addColumn({ type: col.Type, label: col.Label, id: col.Id, role: col.Role });
    }

    for (rowIndex in json.Rows) {
        var row = json.Rows[rowIndex];

        var rowCells = [];
        for (cellIndex in row) {
            var cell = row[cellIndex];

            rowCells[cellIndex] = { v: cell.Value };
            if (cell.FormattedValue != null) {
                rowCells[cellIndex].f = cell.FormattedValue;
            }
        }

        data.addRow(rowCells);

    }

    return data;
};



